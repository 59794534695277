import React, { useState } from "react"
import { Link } from "gatsby"
import Dropdown from "./Dropdown"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const MenuLinkItem = ({ to, onClickHandler, title }) => {
  return (
    <li className="my-0 mx-5 md:mx-3 text-base">
      <Link to={to} onClick={onClickHandler}>
        {title}
      </Link>
    </li>
  )
}

const Navbar = () => {
  const { title } = useSiteMetadata()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const menuToggleHandler = () => setMenuIsOpen(prevState => !prevState)
  const closeMenuHandler = () => setMenuIsOpen(false)

  return (
    <nav className="flex flex-wrap mb-3 md:justify-center md:items-center py-2 bg-gray-100">
      <Link to="/" className="justify-self-start ml-5 text-3xl font-medium">
        {title}
      </Link>
      <div className="flex flex-grow justify-end items-end">
        <button onClick={menuToggleHandler} className="md:hidden px-2">
          {menuIsOpen ? (
            <FontAwesomeIcon icon={faTimes} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faBars} size="lg" />
          )}
        </button>
      </div>
      <ul
        className={`${
          menuIsOpen ? "block" : "hidden"
        } w-full mt-3 md:mt-0 md:w-auto md:flex md:flex-grow md:items-center md:justify-end`}
      >
        <MenuLinkItem
          to="/"
          title="Portfolio"
          onClickHandler={closeMenuHandler}
        />
        <MenuLinkItem
          to="/about"
          title="Blog"
          onClickHandler={closeMenuHandler}
        />
        <MenuLinkItem to="/" title="Tools" onClickHandler={closeMenuHandler} />
        <MenuLinkItem
          to="/contact"
          title="Lernen"
          onClickHandler={closeMenuHandler}
        />
        {dropdownIsOpen && <Dropdown />}
      </ul>
    </nav>
  )
}

export default Navbar
